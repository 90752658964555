import React from 'react';

//import Layout from '../components/layout';
import { StaticQuery, graphql, Link } from "gatsby"
import PortfolioCard from "../components/portfolio"
import ContextConsumer from "../components/context"
import ContextUpdater from "../components/context_updater"


const AboutPage = ({data}) => {
	const handlePortfolioClick = (work) => {
		//alert("portfolio clicked: " + work.node.title);
	}

	var header_text_colour="#b6c6e7";
	var text_colour="#b6c6e7";
	var body_colour="#0a1626";	

	// Move this to navbar
	if (false){
		var logo = data.settings.edges[0].node.localLogoLight;
	} else {
		var logo = data.settings.edges[0].node.localLogoDark;
	}

	return (
  		<ContextConsumer>
		{cdata => 
			<>
			<ContextUpdater
				context={cdata}
				newContext={
				  {
					caller: "about",
					body_colour: body_colour,
					header_text_colour: header_text_colour,
					text_colour: text_colour,
					header_image: false,
				  }
				}
			/>
        	<div className="container">
          		<div className="home-header">
              		<div className="columns">
                		<div className="column is-three-fifths is-offset-one-fifth">
							{/*<p className="lead">{ data.settings.edges[0].node.intro }</p>*/}
                            <p className="lead center">I have been working full time in web development and design for the past 15 years. Starting as a frontend developer and designer and progressing to backend development, systems management and devops.</p>
                        </div>
                    </div>
              		<div className="columns">
                		<div className="column is-full">
                            <div className="content">

                            <p style={{textAlign: "center"}}>I have a proven track record for designing and developing large scale content driven websites and applications for clients. In my career I have worked for clients such as  L’Oréal, BMW, Mini, House Builders Federation, New-Homes.co.uk and BuildUK.</p>


                            <h2 style={{color: text_colour, textAlign: "center"}}>My Skills</h2>

                      		<div className="columns">

                      		<div className="column is-one-third">
                              <h3 style={{color: text_colour, opacity: 0.6}}>Frontend Web Development</h3>
                              <p><ul>
                                  <li>HTML</li>
                                  <li>CSS</li>
                                  <li>Javascript</li>
                                  <li>SASS/LESS</li>
                              </ul></p>
                            </div>

                      		<div className="column is-one-third">
                              <h3 style={{color: text_colour, opacity: 0.6}}>Backend Web Development</h3>
                              <p><ul>
                                <li>Python</li>
                                <li>PHP</li>
                                <li>Perl</li>
                                <li>Javascript</li>
                              </ul></p>
                            </div>

                      		<div className="column is-one-third">
                              <h3 style={{color: text_colour, opacity: 0.6}}>App Development</h3>
                              <p><ul>
                                <li>React</li>
                                <li>JS</li>
                                <li>Expo</li>
                                <li>Ionic</li>
                                <li>Mobile Web Apps</li>
                              </ul></p>
                            </div>

                            </div>

                      		<div className="columns">

                      		<div className="column is-one-third">
                              <h3 style={{color: text_colour, opacity: 0.6}}>Systems Management</h3>
                              <p><ul>
                                <li>Kubernetes</li>
                                <li>Docker</li>
                                <li>Bash</li>
                                <li>Linux (Ubuntu / RedHat)</li>
                                <li>MySQL</li>
                                <li>MongoDB</li>
                                <li>ElasticSearch</li>
                              </ul></p>
                            </div>

                      		<div className="column is-one-third">
                              <h3 style={{color: text_colour, opacity: 0.6}}>Graphic Design</h3>
                              <p><ul>
                                <li>Photoshop</li>
                                <li>Illustrator</li>
                                <li>Indesign</li>
                                <li>3D Studio Max</li>
                                <li>Blender</li>
                              </ul></p>
                            </div>

                      		<div className="column is-one-third">
                              <h3 style={{color: text_colour, opacity: 0.6}}>Blockchain</h3>
                              <p><ul>
                                <li>NXT</li>
                                <li>Sawtooth</li>
                                <li>Hyperledger</li>
                                <li>Iroha</li>
                              </ul></p>
                            </div>

                            </div>

						    </div>
						</div>
					</div>
				</div>
			</div>

    
        <div className="container">
            <div className="columns">
              <div className="column">
                <Link to="/" className="work-footer-link">Work</Link>
              </div>
              <div className="column">
                <Link to="/contact" className="work-footer-link">Contact</Link>
              </div>
            </div>
        </div>
			</>
    	}
  	  </ContextConsumer>
	)
}

export const query = graphql`
  query {
	settings: allRestApiSettings {
	  edges {
		node {
		  title
		  intro
		  home_body_colour
		  home_body_shade
		  home_header_text_colour
		  home_light_or_dark
		  home_text_colour
		  localLogoDark {
			  childImageSharp {
				  fluid(maxWidth: 1360) {
					  ...GatsbyImageSharpFluid
				  }
			  }
		  }
		  localLogoLight {
			  childImageSharp {
				  fluid(maxWidth: 1360) {
					  ...GatsbyImageSharpFluid
				  }
			  }
		  }
		}
		}
	  }
	  works: allRestApiWork {
		edges {
		  node {
			pk
			title
			slug
			blurb
			date
			body_colour
			text_colour
			header_text_colour
			image
			localImage {
				childImageSharp {
					fluid(maxWidth: 1360) {
						...GatsbyImageSharpFluid
					}
				}
			}
		  }
		}
	  }
	}
`


export default AboutPage;
